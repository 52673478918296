import styles from "./ChannelGrid.module.css";

import { Link } from "react-router-dom";

export default function ChannelGrid({ title, channels }) {
	return (
		<div className={styles.grid}>
			<div className={styles.grid__title}>{title}</div>
			<div className={styles.grid__grid}>
				{channels.map((ch, i) => (
					<GridItem key={i} channel={ch} />
				))}
			</div>
		</div>
	);
}

function GridItem({ channel }) {
	if (channel.youtube) {
		return <YoutubeGridItem channel={channel} />;
	}
	return <ChannelGridItem channel={channel} />;
}

function ChannelGridItem({ channel }) {
	return (
		<Link to={`/watch/${channel.id}`} className={styles.item}>
			<div
				className={styles.item__image}
				style={{ backgroundImage: `url(${channel.image})` }}
			/>
			<div className={styles.item__info}>
				<div
					className={styles.item__userImage}
					style={{
						backgroundImage: `url(${channel.user_image})`,
					}}
				/>
				<div className={styles.item__info__text}>
					<div className={styles.item__title}>{channel.title}</div>
					<div className={styles.item__userName}>
						{channel.user_name}
					</div>
				</div>
			</div>
			<div className={styles.item__live}>{"LIVE"}</div>
		</Link>
	);
}

function YoutubeGridItem({ channel }) {
	const { youtube } = channel;
	return (
		<div className={styles.item}>
			<div className={styles.item__frameWrapper}>
				<iframe
					className={styles.item__externalFrame}
					width="100%"
					height="100%"
					src={`https://www.youtube-nocookie.com/embed/${youtube}`}
					title="YouTube video player"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				/>
			</div>
		</div>
	);
}
