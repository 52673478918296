import styles from "./buttons.module.css";

import classnames from "classnames";

export function Button({ text, inline, large, onClick }) {
	return (
		<div
			className={classnames(
				styles.button,
				inline ? styles.inline : null,
				large ? styles.large : null
			)}
			onClick={onClick}
		>
			{text}
		</div>
	);
}
