import Modal, { ModalCenter } from "../layout/Modal";
import { Button } from "../common/buttons";
import MarkdownContent from "../common/MarkdownContent";
import State from "../../state";
import { hideWelcomeModalEvent } from "../../analytics";
import config from "../../config";

import { observer } from "mobx-react-lite";

export default observer(function WelcomeModal() {
	if (!config.welcomeModal || !State.showWelcomeModal) {
		return null;
	}

	const { title, content, closeButtonText } = config.welcomeModal;

	function handleClose() {
		State.hideWelcomeModal();
		hideWelcomeModalEvent();
	}

	return (
		<Modal title={title} onClose={handleClose}>
			<MarkdownContent markdown={content} />
			<ModalCenter>
				<Button
					inline
					large
					text={closeButtonText || "Explore the site!"}
					onClick={handleClose}
				/>
			</ModalCenter>
		</Modal>
	);
});
