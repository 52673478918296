import styles from "./Logo.module.css";
import config from "../../config";

export default function Logo({ size }) {
	size = size || 50;

	return (
		<img
			src={config.site.logoSquareUrl}
			alt={config.site.name}
			className={styles.logo}
			style={{
				height: size,
				width: "auto",
			}}
		/>
	);
}
