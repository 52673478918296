import styles from "./ChannelPageChat.module.css";
import MarkdownContent from "../common/MarkdownContent";
import State from "../../state";
import config from "../../config";

import { useEffect, useState, useRef, useCallback } from "react";
import { observer } from "mobx-react-lite";
import sumBy from "lodash/sumBy";
import classnames from "classnames";

const USER_COLORS = config.userColors || [
	"#fe7312",
	"#D85395",
	"#DCE045",
	"#75A495",
	"#BBDA05",
	"#FFD7E2",
	"#FFEB4A",
	"#FF4DB9",
	"#F9B3EB",
	"#eb0450",
	"#f547c3",
	"#7a0406",
];

export default observer(function ChannelPageChat({ channel }) {
	const [collapsed, setCollapsed] = useState(
		window.screen.width > 600 && window.screen.width <= 1000
	);

	if (collapsed) {
		return (
			<div
				className={styles.chat__expand}
				onClick={() => setCollapsed(false)}
			>
				{"«"}
			</div>
		);
	}

	return (
		<div className={styles.chat}>
			<div className={styles.header}>
				{"STREAM CHAT"}
				<div
					className={styles.chat__header__collapse}
					onClick={() => setCollapsed(true)}
				>
					{"»"}
				</div>
			</div>
			<ChatMessages />
			<ChatForm />
		</div>
	);
});

const ChatMessages = observer(() => {
	const messages = State.activeChannelChatMessages;
	const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
	const scroller = useRef();

	useEffect(() => {
		const elem = scroller.current;
		if (isScrolledToBottom) {
			elem.scrollTop = elem.scrollHeight - elem.clientHeight;
		}
	});

	const handleScroll = useCallback(() => {
		const elem = scroller.current;
		const isScrolledToBottomNew =
			elem.scrollHeight - elem.scrollTop - elem.clientHeight < 1;
		if (isScrolledToBottomNew !== isScrolledToBottom) {
			setIsScrolledToBottom(isScrolledToBottomNew);
		}
	}, [isScrolledToBottom]);

	useEffect(() => setIsScrolledToBottom(true), []);

	return (
		<div className={styles.chat__content}>
			<div
				className={styles.chat__scroller}
				ref={scroller}
				onScroll={handleScroll}
			>
				<ChatMessage text="Welcome to the chat room!" system />
				{messages.map(({ id, time, user, text }) => (
					<ChatMessage
						key={id || `${time}_${user}`}
						user={user}
						text={text}
					/>
				))}
			</div>
			<div
				className={classnames(
					styles.chat__moreMessages,
					isScrolledToBottom ? null : styles.visible
				)}
				onClick={() => setIsScrolledToBottom(true)}
			>
				{"More messages below"}
			</div>
		</div>
	);
});

const ChatForm = observer(() => {
	const textbox = useRef(null);

	function submitHandler(evt) {
		evt.preventDefault();

		if (textbox.current.value !== "") {
			State.sendChatMessage(textbox.current.value);
			textbox.current.value = "";
			textbox.current.blur();
		}
	}

	return (
		<form className={styles.form} onSubmit={submitHandler}>
			<input
				ref={textbox}
				type="text"
				className={classnames(styles.form__textbox, "data-hj-allow")}
				placeholder="Send a message"
			/>
			<button className={styles.form__submit}>{"Chat"}</button>
		</form>
	);
});

function ChatMessage({ user, text, system }) {
	return (
		<div
			className={classnames(
				styles.message,
				system ? styles.system : null
			)}
		>
			{user ? (
				<>
					<span
						className={styles.message__user}
						style={{ color: _textColor(user) }}
					>
						{user}
					</span>
					{`: `}
				</>
			) : null}
			<MarkdownContent className={styles.message__text} markdown={text} />
		</div>
	);
}

function _textColor(text) {
	const num = sumBy(text, (char) => char.charCodeAt(0));
	return USER_COLORS[num % USER_COLORS.length];
}
