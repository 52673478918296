import styles from "./HomePage.module.css";
import ChannelGrid from "../layout/ChannelGrid";
import config from "../../config";

export default function HomePage() {
    return (
        <div className={styles.page}>
            {config.sections.map(({ title, channelIds }) => (
                <ChannelGrid
                    key={title}
                    title={title}
                    channels={channelIds.map((id) => config.channels[id])}
                />
            ))}
        </div>
    );
}
