import showdown from "showdown";

var markdownConverter = null;

export default function MarkdownContent({ markdown, className }) {
	return (
		<div
			className={className}
			dangerouslySetInnerHTML={{
				__html: _getMarkdownConverter()
					.makeHtml(markdown)
					.replace(/…/g, "..."),
			}}
		/>
	);
}

function _getMarkdownConverter() {
	if (!markdownConverter) {
		markdownConverter = new showdown.Converter();
		markdownConverter.setFlavor("github");
		markdownConverter.setOption("simplifiedAutoLink", false);
		markdownConverter.setOption("underline", true);
		markdownConverter.setOption("openLinksInNewWindow", true);
	}

	return markdownConverter;
}
