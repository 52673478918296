import config from "./config";

import { makeAutoObservable, autorun, toJS } from "mobx";
import store from "store";
import pick from "lodash/pick";
import forOwn from "lodash/forOwn";

const STORAGE_KEY = "state";

class State {
    activeChannel = null;
    channelTime = {};
    chatMessages = {};

    showWelcomeModal = !!config.welcomeModal;
    popupImageUrl = null;

    constructor() {
        const storedState = store.get(STORAGE_KEY);
        if (storedState) {
            forOwn(storedState, (value, key) => (this[key] = value));
        }

        makeAutoObservable(this);

        this._tickers = [
            setInterval(() => this._updateActiveChannelTime(), 500),
        ];
    }

    get isChannelActive() {
        return this.activeChannel !== null;
    }

    get activeChannelTime() {
        return this.channelTime[this.activeChannel];
    }

    get activeChannelChatMessages() {
        if (!this.isChannelActive) {
            return [];
        }

        const channel = config.channels[this.activeChannel];
        const messages = channel.chat.filter(
            (msg) => msg.time < this.activeChannelTime
        );
        const userMessages = this.chatMessages[this.activeChannel];
        if (userMessages) {
            messages.push(...userMessages);
        }
        messages.sort((a, b) => a.time - b.time);
        return messages;
    }

    setActiveChannel(channelId) {
        this.activeChannel = channelId;
        this.channelTime[this.activeChannel] =
            this.channelTime[this.activeChannel] || 0;
    }

    unsetActiveChannel() {
        this.activeChannel = null;
    }

    _updateActiveChannelTime() {
        if (this.isChannelActive) {
            this.channelTime[this.activeChannel] += 0.5;
        }
    }

    sendChatMessage(text) {
        if (!this.isChannelActive) {
            return;
        }

        if (!this.chatMessages[this.activeChannel]) {
            this.chatMessages[this.activeChannel] = [];
        }

        this.chatMessages[this.activeChannel].push({
            id: Math.random(),
            time: this.activeChannelTime,
            user: "Me",
            text,
        });

        const channel = config.channels[this.activeChannel];
        if (channel.onChatMessage) {
            channel.onChatMessage(text, ({ user, text }) => {
                this.chatMessages[channel.id].push({
                    id: Math.random(),
                    time: this.activeChannelTime,
                    user,
                    text,
                });
            });
        }
    }

    hideWelcomeModal() {
        this.showWelcomeModal = false;
    }

    hidePopupImage() {
        this.popupImageUrl = null;
    }
}

const state = new State();

autorun(() => {
    const storedState = toJS(
        pick(state, ["channelTime", "chatMessages", "showWelcomeModal"])
    );
    store.set(STORAGE_KEY, storedState);
});

export default state;
