import styles from "./StreamVideoPlayer.module.css";

import VideoPlayer from "@faintlines/video-player";

import { useState, useRef } from "react";
import classnames from "classnames";

export default function StreamVideoPlayer({
	source,
	poster,
	loop,
	startMuted,
	startTime,
}) {
	const videoRef = useRef();
	const [playing, setPlaying] = useState(false);
	const [muted, setMuted] = useState(startMuted);
	const [ended, setEnded] = useState(false);

	function handlePlayingChange() {
		if (videoRef.current) {
			setPlaying(!videoRef.current.paused);
		}
	}

	function handleEnded() {
		if (!loop) {
			setEnded(true);
		}
	}

	function handleMetadataLoaded() {
		if (!videoRef.current) {
			return;
		}
		if (typeof startTime !== "number") {
			videoRef.current.play();
		} else if (startTime < videoRef.current.duration || loop) {
			videoRef.current.seekTo(startTime % videoRef.current.duration);
			videoRef.current.play();
		} else {
			setEnded(true);
		}
	}

	function toggleVideo(play) {
		if (videoRef.current) {
			if (play) {
				videoRef.current.play();
			} else {
				videoRef.current.pause();
			}
		}
	}

	return (
		<div className={styles.player}>
			<VideoPlayer
				ref={videoRef}
				className={styles.player__video}
				url={source}
				width="100%"
				height="100%"
				poster={poster}
				preload="auto"
				loop={loop}
				muted={muted}
				onPlay={handlePlayingChange}
				onPause={handlePlayingChange}
				onEnded={handleEnded}
				onLoadedMetadata={handleMetadataLoaded}
			/>
			<PlayButton
				color="#fff"
				ended={ended}
				visible={!playing}
				onClick={() => toggleVideo(true)}
			/>
			<Controls
				playing={playing}
				muted={muted}
				onPause={() => toggleVideo(false)}
				onPlay={() => toggleVideo(true)}
				onMute={() => setMuted(true)}
				onUnmute={() => setMuted(false)}
			/>
			{ended ? null : <div className={styles.live}>{"LIVE"}</div>}
		</div>
	);
}

function PlayButton({ color, visible, ended, onClick }) {
	return (
		<div
			className={classnames(
				styles.playButton,
				visible ? styles.visible : null
			)}
			onClick={onClick}
		>
			{ended ? (
				<div className={styles.playButton__ended}>
					{"Live stream ended. Replay?"}
				</div>
			) : null}
			<i className="icon-play-1" />
		</div>
	);
}

function Controls({ playing, muted, onPlay, onPause, onMute, onUnmute }) {
	return (
		<div
			className={classnames(
				styles.controls,
				playing ? null : styles.visible
			)}
		>
			{playing ? (
				<div
					className={classnames(
						styles.controls__button,
						styles.pause
					)}
					onClick={onPause}
				>
					<i className="icon-pause-1" />
				</div>
			) : (
				<div
					className={classnames(styles.controls__button, styles.play)}
					onClick={onPlay}
				>
					<i className="icon-play-1" />
				</div>
			)}
			{muted ? (
				<div
					className={classnames(
						styles.controls__button,
						styles.unmute
					)}
					onClick={onUnmute}
				>
					<i className="icon-volume-off" />
				</div>
			) : (
				<div
					className={classnames(styles.controls__button, styles.mute)}
					onClick={onMute}
				>
					<i className="icon-volume-up-1" />
				</div>
			)}
		</div>
	);
}
