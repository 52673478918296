import styles from "./StreamingWebsite.module.css";

import SiteHeader from "./components/layout/SiteHeader";
import HomePage from "./components/pages/HomePage";
import ChannelPage from "./components/pages/ChannelPage";
import WelcomeModal from "./components/modals/WelcomeModal";
import config from "./config";

import Widget from "@faintlines/widget";
import "@faintlines/widget/dist/index.css";

import { Router, Switch, Route } from "react-router-dom";
import { observer } from "mobx-react-lite";

export default observer(function App({ history }) {
    return (
        <Router history={history}>
            <div className={styles.app}>
                <SiteHeader />
                <Switch>
                    <Route path="/watch/:channelId" exact>
                        <ChannelPage />
                    </Route>
                    <Route path="/" exact>
                        <HomePage />
                    </Route>
                </Switch>
                <WelcomeModal />
                {config.widget ? (
                    <Widget config={config.widget} storyConfig={config.story} />
                ) : null}
            </div>
        </Router>
    );
});
