import styles from "./Modal.module.css";

import classnames from "classnames";
import { useState, useEffect } from "react";

export default function Modal({
	title,
	children,
	noPadding,
	enterAnimation,
	onClose,
}) {
	const [enter, setEnter] = useState(!enterAnimation);

	useEffect(() => {
		if (enterAnimation) {
			setTimeout(() => setEnter(true), 10);
		}
	}, [enterAnimation]);

	return (
		<div
			className={classnames(styles.modal, enter ? styles.enter : null)}
			onClick={onClose}
		>
			<div
				className={classnames(
					styles.modal__card,
					enter ? styles.enter : null,
					noPadding ? styles.noPadding : null
				)}
				onClick={(evt) => evt.stopPropagation()}
			>
				<div className={styles.modal__contents}>
					<div
						className={styles.modal__closeButton}
						onClick={onClose}
					>
						{"x"}
					</div>
					{title ? (
						<div className={styles.modal__title}>{title}</div>
					) : null}
					<div className={styles.modal__text}>{children}</div>
				</div>
			</div>
		</div>
	);
}

export function ModalCenter({ children }) {
	return <div className={styles.modal__center}>{children}</div>;
}
