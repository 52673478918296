import styles from "./ChannelPage.module.css";
import ChannelPageChat from "./ChannelPageChat";
import StreamVideoPlayer from "../common/StreamVideoPlayer";
import config from "../../config";
import State from "../../state";
import ExternalLink from "react-feather/dist/icons/external-link";

import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet-async";

export default observer(function ChannelPage() {
	const { channelId } = useParams();
	const channel = config.channels[channelId];

	useEffect(() => {
		State.setActiveChannel(channelId);
		return () => State.unsetActiveChannel();
	});

	return (
		<div className={styles.page}>
			<Helmet>
				<title>{`${channel.id} - ${config.site.name}`}</title>
			</Helmet>
			<ChannelPageMain channel={channel} />
			<ChannelPageChat channel={channel} />
		</div>
	);
});

function ChannelPageMain({ channel }) {
	return (
		<div className={styles.main}>
			<ChannelPageVideo channel={channel} />
			<div className={styles.main__info}>
				<div
					className={styles.main__userImage}
					style={{
						backgroundImage: `url(${channel.user_image})`,
					}}
				/>
				<div className={styles.main__info__text}>
					<div className={styles.main__userName}>
						{channel.user_name}
					</div>
					<div className={styles.main__title}>{channel.title}</div>
				</div>
			</div>
			<AboutSection
				profile={config.profiles && config.profiles[channel.id]}
			/>
		</div>
	);
}

const ChannelPageVideo = observer(function ({ channel }) {
	return (
		<div className={styles.video}>
			<StreamVideoPlayer
				source={channel.video}
				loop={channel.loop}
				startTime={State.channelTime[channel.id]}
			/>
		</div>
	);
});

function AboutSection({ profile }) {
	if (!profile) {
		return null;
	}

	const { name, bio, followers, social } = profile;

	return (
		<div className={styles.about}>
			<div className={styles.about__title}>{`About ${name}`}</div>
			<div className={styles.about__grid}>
				<div className={styles.about__grid__left}>
					<div
						className={styles.about__followers}
					>{`${followers} followers`}</div>
					{bio ? (
						<div className={styles.about__bio}>{bio}</div>
					) : null}
				</div>
				<div className={styles.about__grid__right}>
					{social && social.top ? (
						<SocialLink
							text="Top profile"
							url={`https://top.social/${social.top}`}
						/>
					) : null}
				</div>
			</div>
		</div>
	);
}

function SocialLink({ url, text }) {
	return (
		<a
			href={url}
			target="_blank"
			rel="noreferrer"
			className={styles.about__socialLink}
		>
			{text}
			<ExternalLink size="1em" />
		</a>
	);
}
