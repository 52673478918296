import "./index.css";
import config from "./config";
import StreamingWebsite from "./StreamingWebsite";
import "./fonts/fontello/fontello-embedded.css";

// import { initAnalytics, initSentry } from "@faintlines/monitoring";

import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Helmet, HelmetProvider } from "react-helmet-async";

// initSentry({
// 	dsn: "https://c00454cf9c2e4d1b9b00fef8fab22829@o574655.ingest.sentry.io/5729407",
// });

const history = createBrowserHistory();

// initAnalytics({ trackingId: "UA-194968253-3", history });

ReactDOM.render(
	<React.StrictMode>
		<HelmetProvider>
			<Helmet>
				<title>{config.site.name}</title>
				{config.site.description ? (
					<meta
						name="description"
						content={config.site.description}
					/>
				) : null}
				{config.site.favicon ? (
					<link rel="icon" href={config.site.favicon} />
				) : null}
			</Helmet>
			<StreamingWebsite history={history} />
		</HelmetProvider>
	</React.StrictMode>,
	document.getElementById("root")
);
